const months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sept',
  'Oct',
  'Nov',
  'Dec',
];

function addZero(value) {
  if (value < 10) {
    return `0${value}`;
  }
  return `${value}`;
}

function format(value) {
  const d = new Date(value);
  const year = d.getFullYear(); // 2019
  const date = d.getDate(); // 23
  const monthName = months[d.getMonth()];

  return `${monthName} ${date}, ${year}`;
}

function getInputDateFormat(time) {
  const date = new Date(time);
  return (
    date.getFullYear() +
    '-' +
    addZero(date.getMonth() + 1) +
    '-' +
    addZero(date.getDate()) +
    'T' +
    addZero(date.getHours()) +
    ':' +
    addZero(date.getMinutes()) +
    ':' +
    addZero(date.getSeconds())
  );
}

function getAmericanFootballTime(value) {
  if (value.length === 2) {
    if (value[1] < value[0]) return ['00', '00'];

    const time = value[1] - value[0];
    const minutes = parseInt((Math.abs(time) / (1000 * 60)) % 60);
    const seconds = parseInt((Math.abs(time) / 1000) % 60);

    return [addZero(minutes), addZero(seconds)];
  }

  const now = Date.now();
  if (value[0] < now) return ['00', '00'];

  const time = value[0] - now;
  const minutes = parseInt((Math.abs(time) / (1000 * 60)) % 60);
  const seconds = parseInt((Math.abs(time) / 1000) % 60);

  return [addZero(minutes), addZero(seconds)];
}

function getBasketballTime(value) {
  if (value.length === 2) {
    if (value[1] < value[0]) return ['00', '00'];

    const time = value[1] - value[0];
    const minutes = parseInt((Math.abs(time) / (1000 * 60)) % 60);
    const seconds = parseInt((Math.abs(time) / 1000) % 60);

    return [addZero(minutes), addZero(seconds)];
  }

  const now = Date.now();
  if (value[0] < now) return ['00', '00'];

  const time = value[0] - now;
  const minutes = parseInt((Math.abs(time) / (1000 * 60)) % 60);
  const seconds = parseInt((Math.abs(time) / 1000) % 60);

  return [addZero(minutes), addZero(seconds)];
}

function getChatTime(value) {
  if (!value) return '';
  const now = Date.now();
  if (value < now) return '00:00';
  const time = now - value;
  const minutes = parseInt((Math.abs(time) / (1000 * 60)) % 60);
  const seconds = parseInt((Math.abs(time) / 1000) % 60);

  return `${addZero(minutes)}:${addZero(seconds)}`;
}

function getCountdownTime(value) {
  if (value.length === 2) {
    if (value[1] < value[0]) return ['00', '00', '00'];

    const time = value[1] - value[0];
    const hours = parseInt(Math.abs(time) / (1000 * 60 * 60));
    const minutes = parseInt((Math.abs(time) / (1000 * 60)) % 60);
    const seconds = parseInt((Math.abs(time) / 1000) % 60);

    return [addZero(hours), addZero(minutes), addZero(seconds)];
  }

  const now = Date.now();
  if (value[0] < now) return ['00', '00', '00'];

  const time = value[0] - now;
  const hours = parseInt(Math.abs(time) / (1000 * 60 * 60));
  const minutes = parseInt((Math.abs(time) / (1000 * 60)) % 60);
  const seconds = parseInt((Math.abs(time) / 1000) % 60);

  return [addZero(hours), addZero(minutes), addZero(seconds)];
}

function getCountUpTime(value) {
  if (value.length === 2) {
    if (value[1] < value[0]) return ['00', '00', '00'];

    const time = value[1] - value[0];
    const hours = parseInt(Math.abs(time) / (1000 * 60 * 60));
    const minutes = parseInt((Math.abs(time) / (1000 * 60)) % 60);
    const seconds = parseInt((Math.abs(time) / 1000) % 60);

    return [addZero(hours), addZero(minutes), addZero(seconds)];
  }

  const now = Date.now();
  if (value[0] > now) return ['00', '00', '00'];

  const time = now - value[0];
  const hours = parseInt(Math.abs(time) / (1000 * 60 * 60));
  const minutes = parseInt((Math.abs(time) / (1000 * 60)) % 60);
  const seconds = parseInt((Math.abs(time) / 1000) % 60);

  return [addZero(hours), addZero(minutes), addZero(seconds)];
}

function getTimeUntil(value) {
  const now = Date.now();
  if (value < now) return ['00', '00'];

  const time = value - now;
  const minutes = parseInt((Math.abs(time) / (1000 * 60)) % 60);
  const seconds = parseInt((Math.abs(time) / 1000) % 60);

  return [addZero(minutes), addZero(seconds)];
}

function getSoccerTime(time) {
  const endDate = time[1] || new Date();
  const minutes = parseInt(Math.abs(endDate - time[0]) / (1000 * 60));
  const seconds = parseInt((Math.abs(endDate - time[0]) / 1000) % 60);

  return [addZero(minutes), addZero(seconds)];
}

export default {
  addZero,
  format,
  getAmericanFootballTime,
  getBasketballTime,
  getChatTime,
  getCountdownTime,
  getCountUpTime,
  getInputDateFormat,
  getTimeUntil,
  getSoccerTime,
};
